import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";

// RECAPTCHA
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// ACTIONS
import {
    hideBottomNav,
    hideHeader,
    hideSideDrawer,
    logOut,
} from "../../../state/actions";

// HOOKS
import { useFAQs, useGlobal } from "../../../hooks/strapi";

// COMPONENTS
import { Col, Container, Form, Row, Alert } from "reactstrap";
import Icon from "../../atoms/Icon/Icon";
import Heading from "../../atoms/Heading/Heading";
import Button from "../../atoms/Button/Button";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import FormElement from "../../atoms/FormElement/FormElement";

// MODELS
import contactFormModel from "../../../formModels/contactFormModel";

// UTILS
import { changeHandler, blurHandler, validateForm } from "../../../utils/forms";

// STYLES
import "./helpView.scss";

const HelpView = ({ showForm }) => {
    const dispatch = useDispatch();
    const faqs = useFAQs();
    const global = useGlobal();
    const [question, setQuestion] = useState(null);
    const [isContactForm, setIsContactForm] = useState(showForm);
    const [contactForm, setContactForm] = useState(contactFormModel);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrorMsg, setFormErrorMsg] = useState("");
    const [formSuccessMsg, setFormSuccessMsg] = useState("");

    const contactSubjects = global.contact_subjects.split(";");

    // INITIALIZE RECAPTCHA
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        dispatch(hideHeader());
        dispatch(hideBottomNav());
        dispatch(hideSideDrawer());
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();

        setFormErrorMsg("");
        setFormSuccessMsg("");
        setIsLoading(true);

        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            setIsLoading(false);
            return;
        }

        // GET RECAPTCHA TOKEN
        const token = await executeRecaptcha();

        axios
            .post(`${process.env.STRAPI_API_URL}/send-email`, {
                subject: contactForm.subject.value,
                email: contactForm.email.value,
                message: contactForm.msg.value,
                token,
            })
            .then((res) => {
                setContactForm(contactFormModel);
                setFormSuccessMsg("Dziękujemy za wysłanie wiadomości.");
                setIsLoading(false);
            })
            .catch((err) => {
                const { status } = err.response;
                setIsLoading(false);
                setFormErrorMsg(
                    "Wysyłanie wiadomości nie powiodło się. Proszę spróbować później."
                );

                if (status === 401 || status === 403 || status === 405) {
                    dispatch(logOut());
                    navigate("/app/logowanie");
                }
            });
    };

    if (question === null && isContactForm === false)
        return (
            <Container className="help-view help-view__faq">
                <Row>
                    <Col>
                        <Button
                            className="btn--circle btn--back"
                            onClick={(_) => navigate("/trasy")}
                            aria-label="Cofnij do poprzedniego widoku"
                        >
                            <Icon className="icon-chevron-left-min" />
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-5 help-view__header">
                    <Col>
                        <Heading as="h2">Pomoc</Heading>
                    </Col>
                </Row>
                <Row style={{ borderTop: "1px solid #DFDFDF" }}>
                    <Col className="help-view__questions">
                        {faqs.map((faq, index) => (
                            <Button
                                key={index}
                                onClick={(_) => setQuestion(faq)}
                                className="d-flex justify-content-between"
                                style={{
                                    borderBottom: "1px solid #DFDFDF",
                                    margin: "1.82rem 0 1.32rem 0",
                                }}
                                aria-label={`Pytania i odpowiedzi: ${faq.name}`}
                            >
                                <div>
                                    <Heading
                                        as="h3"
                                        className="font-weight-normal text-left"
                                    >
                                        {faq.name}
                                    </Heading>
                                </div>
                                <div>
                                    <Icon className="icon-arrow-forth icon--alt" />
                                </div>
                            </Button>
                        ))}
                    </Col>
                </Row>
                <Button
                    className="btn--primary help-view__contact"
                    style={{ position: "fixed" }}
                    onClick={(_) => setIsContactForm(true)}
                >
                    Skontaktuj się z nami
                </Button>
            </Container>
        );
    else if (question !== null)
        return (
            <Container className="help-view">
                <Row>
                    <Col>
                        <Button
                            className="btn--circle btn--back"
                            onClick={(_) => setQuestion(null)}
                            aria-label="Cofnij do poprzedniego widoku"
                        >
                            <Icon className="icon-chevron-left-min" />
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Heading as="h2">{question.name}</Heading>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Paragraph>{question.description}</Paragraph>
                    </Col>
                </Row>
            </Container>
        );
    else
        return (
            <Container className="help-view">
                <Row>
                    <Col>
                        <Button
                            className="btn--circle btn--back"
                            onClick={(_) => setIsContactForm(false)}
                            aria-label="Cofnij do poprzedniego widoku"
                        >
                            <Icon className="icon-chevron-left-min" />
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <Heading as="h2">Skontaktuj się z nami</Heading>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <Paragraph>
                            Chciałbyś podzielić się z nami swoją opinią? A może
                            potrzebujesz pomocy z aplikacją lub chciałbyś
                            zgłosić jakiś błąd? Skorzystaj z formularza
                            kontaktowego!
                        </Paragraph>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        {formErrorMsg && (
                            <Alert color="danger">{formErrorMsg}</Alert>
                        )}
                        {formSuccessMsg && (
                            <Alert color="success">{formSuccessMsg}</Alert>
                        )}

                        <Form onSubmit={onSubmit}>
                            <FormElement
                                type="select"
                                label="Temat wiadomości"
                                placeholder=""
                                name="subject"
                                value={{
                                    value: contactForm.subject.value || "",
                                    label: contactForm.subject.value || "",
                                }}
                                options={contactSubjects.map((subject) => ({
                                    value: subject,
                                    label: subject,
                                }))}
                                onChange={changeHandler.bind(
                                    null,
                                    setContactForm
                                )}
                            />
                            <FormElement
                                {...contactForm.email}
                                onChange={changeHandler.bind(
                                    null,
                                    setContactForm
                                )}
                                onBlur={blurHandler.bind(null, setContactForm)}
                            />
                            <FormElement
                                {...contactForm.msg}
                                rows={4}
                                onChange={changeHandler.bind(
                                    null,
                                    setContactForm
                                )}
                                onBlur={blurHandler.bind(null, setContactForm)}
                            />
                            <FormElement
                                {...contactForm.acceptance1}
                                onChange={changeHandler.bind(
                                    null,
                                    setContactForm
                                )}
                                onBlur={blurHandler.bind(null, setContactForm)}
                            ></FormElement>
                            <FormElement
                                {...contactForm.acceptance2}
                                onChange={changeHandler.bind(
                                    null,
                                    setContactForm
                                )}
                                onBlur={blurHandler.bind(null, setContactForm)}
                            ></FormElement>
                            <Button
                                className={`btn--primary btn--md ${
                                    isLoading ? "btn--loading" : ""
                                }`}
                                disabled={
                                    isLoading || !validateForm(contactForm)
                                }
                                type="submit"
                            >
                                Wyślij wiadomość
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
};

export default HelpView;
