export default {
    subject: {
        name: "subject",
        id: "subject",
        type: "select",
        required: true,
        value: "",
        label: "Temat kontaktu",
        valid: false,
    },
    email: {
        name: "email",
        id: "email",
        type: "email",
        required: true,
        value: "",
        label: "Podaj adres e-mail",
        valid: false,
    },
    msg: {
        name: "msg",
        id: "msg",
        type: "textarea",
        required: true,
        value: "",
        label: "Wpisz treść wiadomości",
        valid: false,
    },
    acceptance1: {
        name: "acceptance1",
        id: "acceptance1",
        type: "checkbox",
        required: true,
        value: false,
        valid: false,
        label: `Oświadczam, że zapoznałem się z zapisami w <a href="/regulamin" target="_blank">regulaminie</a> serwisu`,
    },
    acceptance2: {
        name: "acceptance2",
        id: "acceptance2",
        type: "checkbox",
        required: true,
        value: false,
        valid: false,
        label: `Oświadczam, że zapoznałem się z zapisami w <a href="/polityka-prywatnosci" target="_blank">polityce prywatności</a> serwisu`,
    },
};
