export const changeHandler = (updateFn, event) => {
    if (event.label) {
        updateFn((current) => {
            return updateFormField("subject", event.value, current);
        });
    } else {
        const { name, value, type, checked } = event.target;
        const newValue = type === "checkbox" ? checked : value;

        updateFn((current) => {
            return updateFormField(name, newValue, current);
        });
    }
};

export const blurHandler = (updateFn, event) => {
    const { name } = event.target;

    updateFn((current) => {
        let output = { ...current };

        if (output[name].invalid && output[name].msg) {
            output[name].invalidMsg = output[name].msg;
        }

        return output;
    });
};

export const updateFormField = (name, value, formData) => {
    const { isInvalid, msg } = validateField(name, value, formData);
    let output = { ...formData };

    output[name] = {
        ...output[name],
        value: value,
        invalid: isInvalid,
        valid: !isInvalid,
        msg: msg,
        invalidMsg: "",
    };

    if (
        output[name].type === "password" &&
        output.password &&
        output.passwordConfirmation
    ) {
        let p = output.password;
        let pc = output.passwordConfirmation;

        if (p.value && pc.value) {
            p.invalid = pc.invalid = p.value !== pc.value;
            p.valid = pc.valid = p.value === pc.value;
            pc.msg = "Hasła nie są identyczne";
            pc.invalidMsg = "";
        }
    }

    return output;
};

export const validateField = (name, value, data) => {
    const emailRegEx = /\S+@\S+\.\S+/;
    const phoneRegEx = /^[0-9+]{9,9}$/;
    let isInvalid = false;
    let msg = "";

    const { required, type, minLength, maxLength } = data[name];

    if (required) {
        if (!value) {
            isInvalid = true;
            msg = "Pole jest wymagane";
        } else {
            if (minLength && value.length < minLength) {
                msg = "Minimalna ilośc znaków to " + minLength;
                isInvalid = true;
            }

            if (maxLength && value.length > maxLength) {
                msg = "Maksymalna ilośc znaków to " + maxLength;
                isInvalid = true;
            }
        }
    } else if (value) {
        if (minLength && value.length < minLength) {
            msg = "Minimalna ilośc znaków to " + minLength;
            isInvalid = true;
        }

        if (maxLength && value.length > maxLength) {
            msg = "Maksymalna ilośc znaków to " + maxLength;
            isInvalid = true;
        }
    }

    if (type === "email" && value && !emailRegEx.test(value)) {
        msg = "To nie jest poprawny adres email";
        isInvalid = true;
    }

    if (name === "phone" && value && !phoneRegEx.test(value)) {
        msg = "To nie jest poprawny numer telefonu";
        isInvalid = true;
    }

    return { isInvalid, msg };
};

export const validateForm = (formData) => {
    for (const field in formData) {
        if (!formData[field].valid) return false;
    }

    return true;
};
